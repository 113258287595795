

/** IE10 and IE11 requires the following for NgClass support on SVG elements
* import 'classlist.js';  - Run `npm install --save classlist.js`.
*/

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es/reflect';


/** Evergreen browsers require these.
 * Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
 */
// import 'core-js/es/reflect';


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 * import 'web-animations-js';  - Run `npm install --save web-animations-js`.
 **/

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * (window as any).__Zone_disable_requestAnimationFrame = true; - disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; -  disable patch onProperty such as onclick
 * (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; -  disable patch specified eventNames
 */

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 * (window as any).__Zone_enable_cross_context_check = true;
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';

/***************************************************************************************************
 * APPLICATION IMPORTS
 * import * as jQuery from 'jquery';
 * window['jQuery'] = jQuery;
 */
